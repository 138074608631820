import {EnvironmentInterface} from "../app/shared/interfaces/environment.interface";


// STAGING

//  export const environment: EnvironmentInterface = {
// reviewTrustPilotsUrl: "https://nl-be.trustpilot.com/evaluate/www.schoonmaakwoede.be",
//   reviewGoogleUrl: "",
//   firebaseConfig: {
//   apiKey: "AIzaSyD3DCqBKFczFK859DAHLmBAhiCuXyA-XSE",
//     authDomain: "sw-be-staging.firebaseapp.com",
//     projectId: "sw-be-staging",
//     storageBucket: "sw-be-staging.appspot.com",
//     messagingSenderId: "142730133899",
//     appId: "1:142730133899:web:75c1c03821d82f97d42f8e",
//     measurementId: "G-5CKBBRT5W7",
//     locationId: "europe-west",
// },
// production: false,
//   name: "Staging",
//   releaseVersion: "00.00.03",
//   apiUrl: 'https://europe-west1-sw-be-staging.cloudfunctions.net',
//   redirectUrl: "https://sw-staging-be-order.web.app/",
//   baseUrl: "https://staging.schoonmaakwoede.be",
//   localeRedirectUrl: 'https://sw-be-staging-website-en.web.app'
// };




// PRODUCTION

export const environment: EnvironmentInterface = {
  reviewTrustPilotsUrl: "https://nl-be.trustpilot.com/evaluate/www.schoonmaakwoede.be",
  reviewGoogleUrl: "",
  firebaseConfig: {
    apiKey: "AIzaSyAmcJYtkxNVp3PqU7RVWUXQzurq9nPCkdw",
    authDomain: "schoonmaakwoede-be-productie.firebaseapp.com",
    projectId: "schoonmaakwoede-be-productie",
    storageBucket: "schoonmaakwoede-be-productie.appspot.com",
    messagingSenderId: "375684515243",
    appId: "1:375684515243:web:b27c198c433f342d2cbeee",
    measurementId: "G-RQNR47S41P",
    locationId: "europe-west",
  },
  production: false,
  name: "Production",
  releaseVersion: "00.01.00",
  apiUrl: "https://europe-west1-schoonmaakwoede-be-productie.cloudfunctions.net",
  redirectUrl: "https://bestel.schoonmaakwoede.be/",
  baseUrl: "https://schoonmaakwoede.be",
  localeRedirectUrl: 'https://english.schoonmaakwoede.be',
};
