import {Component, Inject, Input, PLATFORM_ID} from "@angular/core";
import {NavigationInterface} from "../../interfaces/navigation.interface";
import {SlideInAnimation, SlideOutAnimation} from "../../utils/animations";
import {environment} from "../../../../environments/environment";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'sw-header',
  templateUrl: './header.component.html',
  animations: [SlideInAnimation, SlideOutAnimation],
})
export class HeaderComponent {
  @Input() isScrolled: boolean = false;

  pages: NavigationInterface[] = [{
    label: 'home',
    routeLink: '/',
  }, {
    label: 'alle diensten',
    routeLink: '/schoonmaakdiensten',
  }, {
    label: 'referenties',
    routeLink: '/referenties',
  }, {
    label: 'faq',
    routeLink: '/klantenservice',
  }, {
    label: 'zakelijk',
    routeLink: '/zakelijk',
  }, {
    label: 'contact ons',
    routeLink: '/contact',
  }
  ]

  showHeader: boolean = false;
  redirectUrl: string = `${environment.redirectUrl}login`;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,) {
  }

  switchLocale() {
    const localeRedirectUrl = environment.localeRedirectUrl;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = localeRedirectUrl;
    }
  }
}
