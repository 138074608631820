import {Component} from '@angular/core';
import {SectionItemsInterface} from "../../interfaces/section-items.interface";

@Component({
    selector: 'sw-cleaning-info',
    templateUrl: './cleaning-info.component.html',

})
export class CleaningInfoComponent {
    items: SectionItemsInterface[] = [
        {
            header: 'GRONDIGE SCHOONMAAK',
            icon: 'clean-water',
            text: 'Wij zijn Schoonmaakwoede. Onze naam staat voor de perfecte grondige schoonmaak. Waar ook in België, wanneer je maar wilt. Onze medewerkers zijn goed getraind op expertise en omgangsvormen. Dat zorgt voor een blinkend resultaat en een tevreden klant.'
        },
        {
            header: 'SNEL BEGROOT EN GEBOEKT',
            icon: 'timer',
            text: 'Met ons unieke online begrotings- en bestelsysteem regel je alles snel en makkelijk. Je ziet eerst de exacte prijs voordat je bestelt. We werken papierloos en je betaalt per pin. Met dit eigentijdse systeem boek je een ouderwets grondige schoonmaak. Mét tevredenheidsgarantie.'
        },
        {
            header: 'HET BESTE MATERIAAL',
            icon: 'clock',
            text: 'Wij rijden voor en hebben de beste professionele middelen en materialen bij ons. Het enige wat we nodig hebben is water en stroom. Schoonmaakwoede gebruikt zoveel mogelijk milieuvriendelijke middelen. Want wij houden het milieu ook graag schoon!\n'
        }
    ]
    selectedCarousel: number = 0;
}
