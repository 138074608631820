import {Component, Inject, OnInit, PLATFORM_ID} from "@angular/core";
import {PackageInterface} from "../../shared/interfaces/package.interface";
import {environment} from "../../../environments/environment";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {Meta, Title} from "@angular/platform-browser";
import {PageUrlService} from "../../core/services/pageUrl.service";

@Component({
  selector: "sw-home",
  templateUrl: "./home.component.html",
  animations: []
})
export class HomeComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private pageUrlService: PageUrlService,
    private title: Title,
    private meta: Meta,
  ) {
  }


  ngOnInit() {
    const pageUrl = this.pageUrlService.getPageUrl();
    this.title.setTitle("Schoonmaakwoede, dé landelijke schoonmaak specialist voor uw huis");
    const tags = [
      {
        name: "title",
        content: "Schoonmaakwoede, dé landelijke schoonmaak specialist voor uw huis"
      },
      {
        name: "keywords",
        content: "particulier poetsbedrijf, schoonmaakbedrijf voor particulieren,particuliere schoonmaakdiensten,huis schoonmaken, schoonmaken van huis, huis schoonmaak, grote schoonmaak, verbouw schoonmaak,bouw schoonmaak,verhuis schoonmaak, schoonmaak bij verhuizing, schoonmaak per uur, schoonmaak op maat, schoonmaak specialist, schoonmaak service, schoonmaak service voor particulieren."
      },
      {
        name: "canonical",
        content: pageUrl
      },
      {
        name: "description",
        content: "Uw huis proper laten maken door professionele vakmensen? Bestel direct online de schoonmaak voor uw huis."
      },
      {
        name: "og:title",
        content: "Schoonmaakwoede, dé landelijke schoonmaak specialist voor uw huis"
      },
      {
        name: "og:description",
        content: "Uw huis proper laten maken door professionele vakmensen? Bestel direct online de schoonmaak voor uw huis."
      },
      {
        name: "og:url",
        content: pageUrl
      },
      {
        name: "og:image",
        content: "https://schoonmaakwoede.be/assets/img/schoonmaakwoede_logo.png" // ToDo: add image url
      },
      {
        name: "robots",
        content: "index, follow"
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1.0"
      },
      {
        charset: "UTF-8"
      },
    ];
    tags.forEach((tag: any) => this.meta.updateTag(tag));
  }


  onOpenLink(id: PackageInterface["packageId"]): void {
    if (isPlatformBrowser(this.platformId)) {
      if (id === "es") {
        const url = `${environment.redirectUrl}eindSchoonmaak/location?packageId=${id}`;
        this.document.location.href = url;
      } else {
        const url = `${environment.redirectUrl}location?packageId=${id}`;
        this.document.location.hash = url;
      }
    }
  }
}
