<sw-ratings></sw-ratings>

<div class="w-full bg-white
            flex flex-col">
    <div class="w-full max-w-header
                flex flex-col
                gap-2 p-[0.938rem]
                md:mx-auto my-[1.5rem]
                mb-0 md:mb-[1.5rem]
                md:text-center">
        <h2 class="w-full text-2xl
                    leading-[2.25rem]
                    md:text-[2.375rem]
                    md:leading-[3.563rem]
                    text-darker font-semibold">
            Schoonmaakwoede aan het werk
        </h2>

        <div class="h-1 w-32
                    bg-divider md:mx-auto
                    my-[0.9rem] md:my-[1rem]"></div>
    </div>

    <sw-video></sw-video>

    <div class="w-full md:bg-primary
                text-white md:py-12">
        <sw-zip-code [customBackground]="true"></sw-zip-code>
    </div>
</div>

<div class="w-full bg-white grid">
    <div class="w-full max-w-header
                flex flex-col
                gap-2 p-[0.938rem]
                md:mx-auto mt-[1.5rem]
                md:mt-[5.5rem]
                mb-0 md:mb-[1.5rem]
                md:text-center">
        <h2 class="w-full text-2xl
                    leading-[2.25rem]
                    md:text-[2.375rem]
                    md:leading-[3.563rem]
                    text-darker font-semibold">
            Waarom België kiest voor Schoonmaakwoede
        </h2>

        <div class="h-1 w-32
                    bg-divider md:mx-auto
                    my-[0.9rem] md:my-[1rem]"></div>
    </div>

    <div class="w-full flex p-[0.938rem]
                justify-center items-center">
        <div class="w-full pb-12">
            <sw-cleaning-info></sw-cleaning-info>
        </div>
    </div>
</div>
