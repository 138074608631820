<div class="max-w-[640px]:min-h-screen w-full h-[52rem] md:h-[60rem] max-h-screen bg-[url('https://firebasestorage.googleapis.com/v0/b/schoonmaakwoede-productie.appspot.com/o/images%2Fschoonmaakwoede_home_mobiel.png?alt=media&token=085af9d7-040b-4a2f-bf84-56da0411bf0b')] md:bg-[url('https://firebasestorage.googleapis.com/v0/b/schoonmaakwoede-productie.appspot.com/o/images%2Fschoonmaakwoede_home_desktop.png?alt=media&token=d8468333-7025-4c29-9b14-e8609c81b834')] bg-cover bg-bottom md:bg-center p-[0.938rem] xl:p-0 flex flex-col">
  <div (click)="focusChildInput()"
       [@fadeIn]="isSmallScreen ? (isVisible ? 'visible' : 'hidden') : 'visible'"
       class="w-full max-w-[62.5rem] min-h-fit p-[0.938rem] py-[1.375rem] group sm:p-6 lg:p-12 cursor-text bg-blur rounded-[2.25rem] mt-auto md:mb-12 mx-auto">
    <div class="w-full max-w-[44rem] flex flex-col md:justify-center md:mx-auto gap-4 md:gap-7 items-center md:text-center">
      <div class="w-full text-[1.625rem] text-dark-blue leading-[2.113rem] md:leading-[3.9rem] md:text-5xl font-semibold">
        De nummer 1 in grondige schoonmaak voor je huis!
      </div>
      <div class="w-full text-light-blue leading-[1.875rem] md:leading-9 font-medium md:mb-2 text-xl md:text-2xl">
        50.000 woningen gingen je voor
      </div>
      <div class="w-full flex flex-col md:flex-row justify-between gap-4 text-left text-darker">
        <div class="w-full md:w-1/2 flex flex-col gap-4">
          <div class="w-full flex gap-2">
            <img src="assets/icons/bullet.svg" alt="bullet"/>
            <span>Morgen een proper huis</span>
          </div>
          <div class="w-full flex gap-2">
            <img src="assets/icons/bullet.svg" alt="bullet"/>
            <span>Gecertificeerde schoonmaak professionals</span>
          </div>
        </div>
        <div class="w-full md:w-1/2 flex flex-col gap-4 mb-2">
          <div class="w-full flex gap-2">
            <img src="assets/icons/bullet.svg" alt="bullet"/>
            <span>Klanten geven ons gemiddeld</span>
            <div class="w-6 h-fit min-w-fit px-2 py-1 flex gap-2 cursor-pointer shadow bg-white rounded-lg">
              <img src="assets/icons/start.svg" alt="start"/>
              <span *ngIf="stats$ | async as stats" class="font-semibold text-primary">
                {{ stats?.averageRating | number}}
              </span>
            </div>
          </div>
          <div class="w-full flex items-center gap-2">
            <img src="assets/icons/bullet.svg" alt="bullet"/>
            <span>Binnen één minuut een prijsopgave en reservering</span>
          </div>
        </div>
      </div>
      <form [formGroup]="zipCodeForm" #form=ngForm (ngSubmit)="onStart(form)" class="h-12 w-min-fit w-full relative flex flex-nowrap rounded-full shadow ring-2 ring-transparent hover:ring-primary focus:ring-primary group-hover:ring-primary focus-within:ring-primary" [ngClass]="{ '!ring-[#ef4444]': zipCodeForm.get('zipCode')?.invalid && (zipCodeForm.get('zipCode')?.touched || form?.submitted) }">
        <input #childInput class="bg-white appearance cursor-pointer-none border-none outline-none w-full rounded-l-full focus:!ring-primary p-[0.938rem] text-darker placeholder:text-placeholder" formControlName="zipCode" placeholder="Vul je postcode in"/>
        <button type="submit" class="flex items-center w-fit h-full border border-primary outline-none appearance-none px-3 md:px-9 shadow-primary whitespace-nowrap text-center text-white cursor-pointer ripple rounded-r-full bg-primary">
          Bereken prijs
        </button>
      </form>
    </div>
  </div>
</div>
