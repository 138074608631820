import {Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild} from "@angular/core";
import {FormControl, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {ZIP_CODE_REG_EX} from "../../utils/utils";
import {PackageInterface} from "../../interfaces/package.interface";
import {NavigationEnd, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {DOCUMENT, isPlatformServer} from "@angular/common";

@Component({
  selector: "sw-zip-code",
  templateUrl: "./zip-code.component.html",
})
export class ZipCodeComponent {
  @Input() customBackground?: boolean;
  @ViewChild("zipCodeEl", {static: false}) zipCodeEl?: ElementRef;

  zipCodeForm: FormGroup = new FormGroup({
    zipCode: new FormControl<string>("", [Validators.required, Validators.pattern(ZIP_CODE_REG_EX)])
  });
  isBusiness: boolean = false;
  title: string = "Per direct een vrijblijvende prijsopgave?";
  placeholder = "Vul je postcode in";
  buttonTitle = 'Start';
  isPartner: boolean | undefined;

  constructor(private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object,
              @Inject(DOCUMENT) private document: Document) {
    router.events.subscribe(({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.isBusiness = event?.url?.includes("zakelijk");
          this.isPartner = event?.url?.includes("partner") || event?.url?.includes("klachtenpagina");
          this.title = this.isBusiness ? "We bellen je graag terug" : "Per direct een vrijblijvende prijsopgave?";
          this.placeholder = this.isBusiness ? "Je telefoonnummer" : "Vul je postcode in";
          this.buttonTitle = this.isBusiness ? "Bel me terug!" : "Start";
        }
      }
    }));
  }

  onStart(form: FormGroupDirective): void {
    const zipCode = form.value?.zipCode;
    const result = this.router.url.substring(this.router.url.indexOf("schoonmaakdiensten/")) as string;
    const packageName = result?.replace("schoonmaakdiensten/", "") as string;
    const packageId: PackageInterface["packageId"] | null = this.getPackageId(packageName);
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (form.valid && packageId) {
      if (packageId === "es") {
        const url = `${environment.redirectUrl}eindSchoonmaak/location?packageId=${packageId}&zipCode=${zipCode}`;
        this.document.location.href = url;
      } else {
        const url = `${environment.redirectUrl}location?packageId=${packageId}&zipCode=${zipCode}`;
        this.document.location.href = url;
      }
    } else if (form.valid) {
      const url = `${environment.redirectUrl}location?packageId=gs&zipCode=${zipCode}`;
      this.document.location.href = url;
    }
  }

  getPackageId(packageName: string): PackageInterface["packageId"] | null {
    if (packageName === "eind-schoonmaak")
      return "es";
    else if (packageName === "grote-schoonmaak")
      return "gs";
    else if (packageName === "zwangerschap-schoonmaak")
      return "zs";
    else if (packageName === "voorjaar-schoonmaak")
      return "ls";
    else if (packageName === "verhuis-schoonmaak")
      return "vs";
    else if (packageName === "verbouw-schoonmaak")
      return "rs";
    else if (packageName === "schoonmaak-per-uur")
      return "ms";
    else return null;
  }

  focusChildInput(): void {
    this.zipCodeEl?.nativeElement.focus();
  }
}
