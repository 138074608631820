<div class="w-full bg-secondary
            flex flex-col">

  <div class="w-full h-[36rem] md:h-[50rem]
              bg-[url('https://firebasestorage.googleapis.com/v0/b/schoonmaakwoede-productie.appspot.com/o/images%2Fwie_is_schoonmaakwoede_mobiel.jpeg?alt=media&token=0920703c-6716-43cb-8c40-0fe508445dd9')]
              md:bg-[url('https://firebasestorage.googleapis.com/v0/b/schoonmaakwoede-productie.appspot.com/o/images%2Fwie_wat_schoonmaakwoede_doet.jpeg?alt=media&token=51799de5-2058-4a4a-a0d2-f1fff43602aa')]
              bg-no-repeat
              bg-cover
              bg-bottom
              md:bg-right-bottom
              p-[0.938rem]
              flex flex-col">
    <div class="w-full max-w-[45rem]
                min-h-fit p-8 px-6
                sm:p-16 mt-auto
                lg:px-20 lg:py-28
                bg-blur rounded-[2.25rem]
                md:ml-auto md:my-auto">
      <div class="w-full
                    flex flex-col
                    gap-4 md:gap-8">
        <h2 class="w-full text-2xl sm:text-4xl
                      text-white font-semibold">
          Schoonmaakwoede. Wie we zijn en wat we doen.
        </h2>

        <div class="h-1 w-32
                      opacity-30 bg-white
                      hidden md:flex"></div>

        <div class="w-full text-white
                      leading-[1.6rem]
                      text-[1rem] opacity-70">
          Onze naam staat voor perfectie in grondige schoonmaak. Laat je ontzorgen en ervaar het gevoel van
          een heerlijk kuis huis of bedrijf. Of het nu gaat om een groot of een klein project, wij
          garanderen een blinkend resultaat. Waar ook in België. Ons unieke online begrotings- en
          bestelsysteem maakt het makkelijk.
        </div>
      </div>
    </div>
  </div>

  <!-- Media -->
<!--  <div class="w-full bg-white-->
<!--              flex flex-col p-[0.938rem]-->
<!--              pt-[3.125rem] md:pt-[6.375rem]">-->
<!--    <sw-media-wizard></sw-media-wizard>-->
<!--  </div>-->
  <!-- Media -->
</div>
