<div class="w-full bg-white
            flex flex-col md:space-y-8">

  <!--  Postcode   -->
  <div class="text-white md:text-darker md:pt-[3.5rem]">
    <sw-zip-code></sw-zip-code>
  </div>
  <!--  Postcode  -->

  <div class="h-fit w-full bg-primary
              px-[0.938rem]">

    <div class="h-fit w-full
                max-w-container
                flex flex-wrap
                mt-8 mx-auto md:p-6
                bg-transparent border-none
                shadow shadow-primary
                bg-blur-sm bg-blur rounded-[2.25rem]">
      <div class="w-full sm:w-1/2
                  lg:w-1/4 flex
                  md:px-4
                  flex-col gap-4">
        <img class="h-12 mx-auto md:mx-0 md:mr-auto"
             src="assets/logo.svg"
             alt="logo"/>

        <div class="text-sm mb-4 text-light">
          Perfectie in grondige schoonmaak. Laat je ontzorgen en ervaar het gevoel van een heerlijk schoon
          huis of
          bedrijf
        </div>
      </div>


      <div class="w-full sm:w-1/2
                        lg:w-1/4 flex
                        md:px-4
                        flex-col gap-2">
        <div class="text-white font-semibold">
          Schoonmaakwoede
        </div>

        <div [routerLink]="['/klantenservice']"
             [routerLinkActive]="['font-semibold']"
             class="text-light hover:text-white cursor-pointer capitalize">
          Veel gestelde vragen
        </div>

        <div [routerLink]="['/over-ons']"
             [routerLinkActive]="['text-white']"
             [routerLinkActiveOptions]="{exact: true}"
             class="text-light hover:text-white cursor-pointer capitalize">
          Over ons
        </div>

        <div [routerLink]="['/referenties']"
             [routerLinkActive]="['text-white']"
             [routerLinkActiveOptions]="{exact: true}"
             class="text-light hover:text-white cursor-pointer capitalize">
          Referenties
        </div>

        <div [routerLink]="['/disclaimer']"
             [routerLinkActive]="['text-white']"
             [routerLinkActiveOptions]="{exact: true}"
             class="text-light hover:text-white cursor-pointer capitalize">
          Disclaimer
        </div>

        <div [routerLink]="['/algemene-voorwaarden']"
             [routerLinkActive]="['text-white']"
             [routerLinkActiveOptions]="{exact: true}"
             class="text-light hover:text-white cursor-pointer capitalize">
          Algemene voorwaarden
        </div>

        <div [routerLink]="['/partner']"
             [routerLinkActive]="['text-white']"
             [routerLinkActiveOptions]="{exact: true}"
             class="text-light hover:text-white cursor-pointer capitalize">
          Partner worden
        </div>
      </div>


      <div class="w-full sm:w-1/2
                        lg:w-1/4 flex
                        md:px-4
                        flex-col gap-2">
        <div class="text-white font-semibold">
          Diensten & Pakketten
        </div>

        <div [routerLink]="['/schoonmaakdiensten/grote-schoonmaak']"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLinkActive]="['text-secondary']"
             class="text-light hover:text-white cursor-pointer capitalize">
          Grote Schoonmaak
        </div>

        <div [routerLink]="['/schoonmaakdiensten/oplever-schoonmaak']"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLinkActive]="['text-secondary']"
             class="text-light hover:text-white cursor-pointer capitalize">
           Oplever schoonmaak / Eind schoonmaak
        </div>

        <div [routerLink]="['/schoonmaakdiensten/verhuis-schoonmaak']"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLinkActive]="['text-secondary']"
             class="text-light hover:text-white cursor-pointer capitalize">
          Verhuis Schoonmaak
        </div>

        <div [routerLink]="['/schoonmaakdiensten/verbouw-schoonmaak']"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLinkActive]="['text-secondary']"
             class="text-light hover:text-white cursor-pointer capitalize">
          Verbouw Schoonmaak
        </div>

        <div [routerLink]="['/schoonmaakdiensten/schoonmaak-per-uur']"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLinkActive]="['text-secondary']"
             class="text-light hover:text-white cursor-pointer capitalize">
          Schoonmaak per uur
        </div>

        <div [routerLink]="['/schoonmaakdiensten/maat-schoonmaak']"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLinkActive]="['text-secondary']"
             class="text-light hover:text-white cursor-pointer capitalize">
          Maat Schoonmaak
        </div>

        <div [routerLink]="['/schoonmaakdiensten']"
             [routerLinkActive]="['text-secondary']"
             [routerLinkActiveOptions]="{exact: true}"
             class="text-light hover:text-white cursor-pointer capitalize">
          Alle schoonmaak diensten
        </div>
      </div>

      <div class="w-full sm:w-1/2
                        lg:w-1/4 flex
                        md:px-4
                        flex-col gap-2">
        <div class="text-white font-semibold">
          Contact
        </div>

        <div class="text-light hover:text-white">
          Ma t/m vr: 08:00 - 17:00 uur
        </div>

        <div class="text-light hover:text-white">
          Za: gesloten
        </div>

        <div class="text-light hover:text-white">
          Zo: gesloten
        </div>

        <a class="text-light hover:text-white cursor-pointer" href="tel:003278700106">
          Telefoon: +32 78 700 106
        </a>

        <a class="text-light hover:text-white cursor-pointer break-words"
           href="mailto:contact@schoonmaakwoede.be">Email: contact@schoonmaakwoede.be</a>
      </div>
    </div>

    <div class="text-[#7FA9D8]
                    text-center w-full
                    mx-auto my-4">
      Copyright © 2006-{{ date | date: 'YYYY' }} Schoonmaakwoede.be
    </div>
  </div>
</div>
