import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Analytics, logEvent } from '@angular/fire/analytics';
import {PackageInterface} from "../../shared/interfaces/package.interface";
import {PackageInformationInterface} from "../../shared/interfaces/package-information.interface";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {


  packages: PackageInterface[] = [
    { packageId: "es", name: "Oplever Schoonmaak" },
    { packageId: "gs", name: "Grote Schoonmaak" },
    { packageId: "ls", name: "Voorjaar Schoonmaak" },
    { packageId: "rs", name: "Verbouw Schoonmaak" },
    { packageId: "vs", name: "Verhuis Schoonmaak" },
    { packageId: "zs", name: "Zwangerschap Schoonmaak" },
    { packageId: "ms", name: "Schoonmaak per uur" }
  ];

  constructor(
    private analytics: Analytics,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  trackEvent(eventName: string, eventParams?: { [key: string]: any }) {
    if (isPlatformBrowser(this.platformId)) {
      logEvent(this.analytics, eventName, eventParams);
    }
  }

  logEvent(eventName: string, eventParams: any) {
    if (isPlatformBrowser(this.platformId)) {
      logEvent(this.analytics, eventName, eventParams);
    }
  }


  pushTag(tag: any) {
    if (isPlatformBrowser(this.platformId)) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push(tag);
    }
  }


  trackViewItemList(packages: {
    item_id: string,
    item_name: string,
    index: number
  }[], listId: string = 'alle diensten', listName: string = 'Alle diensten') {
    const items = packages.map((pkg) => ({
      item_id: this.getPackageId(pkg.item_name),
      item_name: pkg.item_name,
      index: pkg.index
    }));

    this.logEvent('view_item_list', {
      ecommerce: {
        item_list_id: listId,
        item_list_name: listName,
        items: items
      }
    })

    this.pushTag({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: listId,
        item_list_name: listName,
        items: items
      }
    });
  }

  trackViewItem(pkg: PackageInformationInterface) {
    const item = {
      item_id: pkg.packageId,
      item_name: pkg.packageName
    };

    this.pushTag({
      event: 'view_item',
      ecommerce: {
        currency: 'EUR',
        items: [item]
      }
    });


    this.logEvent('view_item', {
      ecommerce: {
        currency: 'EUR',
        items: [item]
      }
    })
  }



  trackAddToCart(pkg: PackageInformationInterface, zipCode : string = '' , overwritePkgID? : string) {
    const item = {
      item_id: pkg.packageId,
      item_name: pkg.packageName
    };

    if(overwritePkgID){
      item.item_id = overwritePkgID as any
      item.item_name = this.getPackageName(overwritePkgID) as any
    }

    this.pushTag({
      event: 'add_to_cart',
      packagePage: pkg.packageId,
      ecommerce: {
        currency: 'EUR',
        items: [item]
      }
    });


    this.logEvent('add_to_cart', {
      event: 'add_to_cart',
      packagePage: pkg.packageId,
      ecommerce: {
        currency: 'EUR',
        items: [item]
      }
    })

  }



  private getPackageId(packageName: string): string {
    const pkg = this.packages.find(pkg => pkg.name === packageName);
    return pkg ? pkg.packageId : '';
  }


  private getPackageName(packageId: string): string {
    const pkg = this.packages.find(pkg => pkg.packageId === packageId);
    return pkg ? pkg.name : '';
  }

}
