import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {CmsPageComponent} from "./shared/components/cms-page/cms-page.component";
import {CmsService} from "./core/services/cms.service";


const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./modules/home/home.module").then(m => m.HomeModule)
  }, {
    path: "klantenservice",
    loadChildren: () => import("./modules/faq/faq.module").then(m => m.FaqModule)
  }, {
    path: "schoonmaakdiensten",
    loadChildren: () => import("./modules/packages/packages.module").then(m => m.PackagesModule)
  }, {
    path: "zakelijk",
    loadChildren: () => import("./modules/business/business.module").then(m => m.BusinessModule)
  }, {
    path: "media",
    loadChildren: () => import("./modules/media/media.module").then(m => m.MediaModule)
  }, {
    path: "contact",
    loadChildren: () => import("./modules/contact/contact.module").then(m => m.ContactModule)
  }, {
    path: 'referenties',
    loadChildren: () => import('./modules/references/references.module').then(m => m.ReferencesModule)
  }, {
    path: 'partner',
    loadChildren: () => import('./modules/partner/partner.module').then(m => m.PartnerModule)
  }, {
    path: 'disclaimer',
    loadChildren: () => import('./modules/disclaimer/disclaimer.module').then(m => m.DisclaimerModule)
  }, {
    path: 'algemene-voorwaarden',
    loadChildren: () => import('./modules/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
  }, {
    path: 'over-ons',
    loadChildren: () => import('./modules/about-us/about-us.module').then(m => m.AboutUsModule)
  }, {
    path: 'klachtenpagina',
    loadChildren: () => import('./modules/complaints/complaints.module').then(m => m.ComplaintsModule)
  }, {
    path: 'bestelproces',
    loadChildren: () => import('./modules/order-process/order-process.module').then(m => m.OrderProcessModule)
  }, {
    path: 'schoonmaak-kwaliteit-systeem',
    loadChildren: () => import('./modules/cleaning-quality-system/cleaning-quality-system.module').then(m => m.CleaningQualitySystemModule)
  },
  {
    path: "**",
    component: CmsPageComponent,
    resolve: {
      cmsContent: CmsService,
    },
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
      scrollPositionRestoration: 'top'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
